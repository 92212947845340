
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { bus } from "@/main";
  import { formatCurrency, formatDate } from "@/utils/formatters";
  import { BidStatus } from "@/enums/bid";

  type type = "date" | "expiration_date" | "amount" | "candidates" | "suspensive_conditions" | "extra" | "CTA";

  export interface BidRowColumn {
    type: type;
    showInHeader?: boolean;
    label?: string;
    width?: string;
    center?: boolean;
    end?: boolean;
    headerCenter?: boolean;
  }

  @Component({
    components: {
      GeneralItemRow: () => import("@/components/general/item-row.vue"),
    },
  })
  export default class BidRow extends Vue {
    @Prop() activity!: Activity;

    @Prop() bid!: Bid;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: true }) canReopen!: boolean;

    @Prop() columns!: BidRowColumn[];

    @Prop({ default: () => "link" }) mode?: "link" | "accordion";

    bus = bus;

    formatCurrency = formatCurrency;

    formatDate = formatDate;

    get itemLink() {
      if (this.mode !== "link") {
        return;
      }

      return { name: "dossier-bid-update", params: { id: this.activity.id, bidId: this.bid.id } };
    }

    get representatives() {
      if (this.bid.representatives.length > 2) {
        return `${this.bid.representatives[0].first_name} ${this.bid.representatives[0].last_name}, ${this.bid.representatives.length - 1} meer`;
      }

      return this.bid.representatives.map((r) => `${r.first_name} ${r.last_name}`).join(", ");
    }

    get canUnveil() {
      return this.bid.status === BidStatus.Candidate && this.bid.visible === false;
    }

    get canApprove() {
      return this.bid.status === BidStatus.Candidate && this.bid.visible === true;
    }

    get canReject() {
      return this.bid.status === BidStatus.Candidate;
    }

    get canUndoReject() {
      return this.bid.status === BidStatus.Rejected && this.canReopen;
    }
  }
